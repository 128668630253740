import React from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";
import BoxTypo from "../components/BoxTypo";
import NewsArea from "../components/newsArea";
import { StoreInfo } from "../components/StoreInfo";
import MediaQuery from "react-responsive";
import Pics from "../components/pics";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      topImageSp: file(relativePath: { eq: "pivot_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dhiaborittyo: file(relativePath: { eq: "dhiaborittyo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      piccoro: file(relativePath: { eq: "piccoro.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      topImagePc: file(relativePath: { eq: "piccante_fv_pc.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinerImage: file(relativePath: { eq: "diner-picture.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      howtousePc: file(relativePath: { eq: "piccante_howtouse_pc.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      howtouseSp: file(relativePath: { eq: "piccante_howtouse_sp.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pivot_insideImg: file(relativePath: { eq: "pivot_insideImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pivot_tencho: file(relativePath: { eq: "pivot_tencho.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FB: file(relativePath: { eq: "facebook_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IG: file(relativePath: { eq: "instagram_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ReverceDinerPicture: file(
        relativePath: { eq: "reverce-diner-picture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMicrocmsNews {
        edges {
          node {
            newsId
            title
            date
            content
            image {
              url
            }
          }
        }
      }
    }
  `);

  const companyLists = ["Address", "Tell", "mail", "Time"];
  const companyListsSec = [
    "石川県金沢市片町2-24-11 サンローゼ片町ビル 3階",
    "076-261-2411",
    "pivot@piccante.co.jp",
    "20:00 ~ 翌5:00 定休日(木)",
  ];

  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#731818">
        <Header textColor="#FFFFFF" BGColor="#731818" />
        <Box
          className="fv"
          width="100%"
          margin="auto"
          py={13}
          position="relative"
          bgcolor="#731818"
        >
          <Img fluid={data.topImageSp.childImageSharp.fluid} />
          <Box margin="auto" width="90%">
            <BoxTypo
              fs={["24px", "36px", "48px"]}
              mt={[5, 10]}
              fw="700"
              color="#fff"
              ls="0"
            >
              “Message .
            </BoxTypo>
            <BoxTypo margin="auto" width="95%" fs="14px" color="#fff">
              メッセージ
            </BoxTypo>
          </Box>

          <BoxTypo
            fs={["16px", "24px", "33px"]}
            ls={["1px", "3px"]}
            lh="200%"
            fw="700"
            width={["90%", "90%", "80%"]}
            margin="auto"
            mt={[3, 3, 5]}
            color="#fff"
          >
            金沢一の繁華街『片町』にあるカジュアルバー
          </BoxTypo>
          <BoxTypo
            fs={["14px", "16px", "18px"]}
            ls={["1px", "3px"]}
            lh={["180%", "200%"]}
            textAlign="justify"
            width="85%"
            mt={3}
            m={"auto"}
            color="#fff"
          >
            たくさんのカードゲームやカッコいい女性マスターが名物。定番カクテルの他、ちょっと珍しい地ビールやシャンパンをご用意しております。怪獣・妖怪・誰でも入れるあったか〜い場所ですので、お気軽に遊びに来てくださいね！
          </BoxTypo>

          <Box my={[5, 15, 25]}>
            <Img fluid={data.pivot_insideImg.childImageSharp.fluid} />
          </Box>

          <Box textAlign="center">
            <BoxTypo
              fs={["24px", "36px", "48px"]}
              mt={[5, 10]}
              fw="700"
              color="#fff"
              ls="0"
            >
              “Ten cho Shokai.
            </BoxTypo>
            <BoxTypo fs="14px" color="#fff" pb={6}>
              店長の自己紹介
            </BoxTypo>
            <Hidden smDown>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ margin: "20px 0" }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <Box
                    m={"auto"}
                    mb={["30px", "30px", "100px"]}
                    mt={["30px", "20px", "20px"]}
                    ml={["0px", "0px", "120px"]}
                  >
                    <BoxTypo
                      fs={["14px", "16px", "18px"]}
                      ls={["1px", "3px"]}
                      lh={["180%", "180%", "200%"]}
                      textAlign="justify"
                      width="90%"
                      height="auto"
                      // maxWidth="1100px"
                      mt={6}
                      m={"auto"}
                      color="#fff"
                    >
                      こんにちは！店長のしゅうかです！
                      <br />
                      写真だけじゃ想像できないくらいよくしゃべります！
                      <br />
                      好きな動物はカバです！
                      <br />
                      今だにカバ好きとは出会えてません、、我こそはって方お待ちしてます笑
                      <br />
                      1人でもぜっったいに寂しくないお店です！わたしは1人だと寂しいので皆様お待ちしてます！
                      <br />
                    </BoxTypo>
                  </Box>
                </Grid>
                <Grid item xs={0} sm={1} lg={1}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  lg={5}
                  justify="center"
                  alignItems="center"
                >
                  <Box
                    xs={6}
                    width={["60%", "70%", "70%"]}
                    height="auto"
                    textAlign="left"
                  >
                    <Img fluid={data.pivot_tencho.childImageSharp.fluid} />
                  </Box>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ margin: "20px 0" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  lg={5}
                  justify="center"
                  alignItems="center"
                >
                  <Box
                    xs={6}
                    width={["60%", "70%", "70%"]}
                    height="auto"
                    textAlign="center"
                    margin="auto"
                  >
                    <Img fluid={data.pivot_tencho.childImageSharp.fluid} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box
                    m={"auto"}
                    mb={["30px", "30px", "100px"]}
                    mt={["40px", "30px", "20px"]}
                    ml={["0px", "0px", "120px"]}
                  >
                    <BoxTypo
                      fs={["12px", "14px", "18px"]}
                      ls={["1px", "3px"]}
                      lh={["180%", "200%"]}
                      textAlign="justify"
                      width="90%"
                      maxWidth="1100px"
                      mt={3}
                      m={"auto"}
                      color="#fff"
                    >
                      こんにちは！店長のしゅうかです！
                      写真だけじゃ想像できないくらいよくしゃべります！
                      <br />
                      好きな動物はカバです！
                      今だにカバ好きとは出会えてません、、 <br />
                      我こそはって方お待ちしてます笑
                      1人でもぜっったいに寂しくないお店です！わたしは1人だと寂しいので皆様お待ちしてます！
                    </BoxTypo>
                  </Box>
                </Grid>
              </Grid>
            </Hidden>
          </Box>
          <Box width="100%" bgcolor="#b8a07c" marginTop="64px">
            <BoxTypo
              color="#fff"
              fs={["24px", "36px", "48px"]}
              width={["90%", "70%", "60%"]}
              m={"auto"}
              fw="bold"
              ls="0"
            >
              “Store info.
            </BoxTypo>
            <BoxTypo
              color="#fff"
              fs={["11px", "12px", "14px"]}
              width={["90%", "70%", "55%"]}
              m={"auto"}
              fw="900"
              ls="10px"
            >
              店舗情報
            </BoxTypo>
          </Box>
          <Box
            display={["block", "block", "block", "flex"]}
            width={["95%", "90%", "70%"]}
            m={"auto"}
            py={["40px", "50px", "60px"]}
          >
            <Box
              display="flex"
              justifyContent="center"
              width={["100%", "100%", "100%", "70%"]}
              py={5}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
              >
                <BoxTypo
                  color="#FFFFFF"
                  // key={index}
                  fs={["12px", "16px", "18px"]}
                  lh="2.0"
                >
                  Address
                </BoxTypo>
                <BoxTypo
                  color="#FFFFFF"
                  // key={index}
                  fs={["12px", "16px", "18px"]}
                  lh="2.5"
                >
                  Tell
                </BoxTypo>
                <BoxTypo
                  color="#FFFFFF"
                  // key={index}
                  fs={["12px", "16px", "18px"]}
                  lh="2.5"
                >
                  mail
                </BoxTypo>
                <BoxTypo
                  color="#FFFFFF"
                  // key={index}
                  fs={["12px", "16px", "18px"]}
                  lh="2.5"
                >
                  Time
                </BoxTypo>
                {/* {companyLists.map((list, index) => (
                  <BoxTypo
                    color="#FFFFFF"
                    key={index}
                    fs={["12px", "16px", "18px"]}
                    lh="2.5"
                  >
                    {list}
                  </BoxTypo>
                ))} */}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                pl={["20px", "30px", "40px"]}
              >
                <BoxTypo
                  width="80%"
                  color="#FFFFFF"
                  // key={index}
                  fs={["10px", "11px", "15px"]}
                  lh="1.5"
                >
                  石川県金沢市片町2-24-11 サンローゼ片町ビル 3階
                </BoxTypo>
                <BoxTypo
                  color="#FFFFFF"
                  // key={index}
                  fs={["12px", "16px", "18px"]}
                  lh="2.5"
                >
                  076-261-2411
                </BoxTypo>
                <BoxTypo
                  color="#FFFFFF"
                  // key={index}
                  fs={["12px", "16px", "18px"]}
                  lh="2.5"
                >
                  pivot@piccante.co.jp
                </BoxTypo>
                <BoxTypo
                  color="#FFFFFF"
                  // key={index}
                  fs={["12px", "16px", "18px"]}
                  lh="2.5"
                >
                  20:00 ~ 翌5:00 定休日(木)
                </BoxTypo>
                {/* {companyListsSec.map((list, index) => (
                  <BoxTypo
                    color="#FFFFFF"
                    key={index}
                    fs={["12px", "16px", "18px"]}
                    lh="2.5"
                  >
                    {list}
                  </BoxTypo>
                ))} */}
              </Box>
            </Box>
            <Hidden lgUp>
              <BoxTypo
                color="#FFFFFF"
                width={["95%", "70%", "70%"]}
                m={"auto"}
                textAlign="center"
                fs={["8px", "10px", "13px"]}
                pb={5}
              >
                カード・電子マネー決済可
              </BoxTypo>
              <BoxTypo
                color="#FFFFFF"
                width={["95%", "70%", "70%"]}
                m={"auto"}
                fs={["8px", "10px", "13px"]}
                pb={5}
              >
                ※年末年始等で変動する場合がございます。インスタグラムを
                <br />
                ご確認いただくか、お電話いただけますと幸いです。
              </BoxTypo>
            </Hidden>
            <MediaQuery query="(max-width: 768px)">
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6409.48165317137!2d136.6511066!3d36.5603616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff8347e34d3ffff%3A0x253be2cf26867485!2z44K144Oz44Ot44O844K854mH55S6!5e0!3m2!1sja!2sjp!4v1640322469601!5m2!1sja!2sjp"
                  width="100%"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>

            <MediaQuery query="(min-width: 769px)">
              <Box display="flex" justifyContent="center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6409.48165317137!2d136.6511066!3d36.5603616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff8347e34d3ffff%3A0x253be2cf26867485!2z44K144Oz44Ot44O844K854mH55S6!5e0!3m2!1sja!2sjp!4v1640322469601!5m2!1sja!2sjp"
                  width="500"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>
          </Box>
          <Hidden mdDown>
            <BoxTypo
              color="#FFFFFF"
              width={["95%", "70%", "70%"]}
              m={"auto"}
              textAlign="center"
              fs={["8px", "10px", "13px"]}
              pb={5}
            >
              カード・電子マネー決済可
            </BoxTypo>
            <BoxTypo
              color="#FFFFFF"
              width={["95%", "90%", "70%"]}
              m={"auto"}
              fs={["8px", "10px", "13px"]}
              pb={5}
            >
              ※年末年始等で変動する場合がございます。インスタグラムを
              <br />
              ご確認いただくか、お電話いただけますと幸いです。
            </BoxTypo>
          </Hidden>
          <Box
            width="500px"
            maxWidth="300px"
            m={"auto"}
            display="flex"
            justifyContent="space-around"
          >
            <Link
              to="https://instagram.com/pivot_2021?utm_medium=copy_link"
              target="_blank"
              rel="noopener noreferrer"
              width="100px"
            >
              <Img
                fluid={data.IG.childImageSharp.fluid}
                style={{ width: "60px", height: "auto" }}
              />
            </Link>
            <Link
              to="https://facebook.com/BAR.Pivot"
              target="_blank"
              rel="noopener noreferrer"
              width="300px"
            >
              <Img
                fluid={data.FB.childImageSharp.fluid}
                style={{ width: "60px", height: "auto" }}
              />
            </Link>
          </Box>
        </Box>
        {/* 2ページ目 */}

        <Footer textColor="#FFFFFF" BGColor="#731818" />
      </Box>
    </>
  );
}
